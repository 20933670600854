import { AxiosResponse } from "axios";
import { CategoryRouter } from "./api-config";
import axiosClient from "./axios-client";
import { BaseResponse } from "./base-response";

export class CategoryDTO {
    id_category: number = 0;
    name_category: string = "";
  }

export const getCategoryList = async (): Promise<BaseResponse<CategoryDTO>> => {
    try {
        const url = CategoryRouter.API_GET_CATEGORY;
        const response: AxiosResponse<BaseResponse<CategoryDTO>> = await axiosClient.get(url, {

        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};