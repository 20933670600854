import { useState } from 'react';
import { Link } from 'react-router-dom';
const Header = () => {
  const [store, setStore] = useState('1');
  const [isOpen, setOpen] = useState(false)
  const handleStoreChange = (e: any) => {
    setStore(e.target.value);
  };

  return (
    <header className=" mt-20 header-section">
      <div className="container">
        <div className="header-wrapper">
          <div onClick={() => { isOpen ? setOpen(false) : setOpen(true) }} className={isOpen ? "header-bar d-lg-none active" : "header-bar d-lg-none "}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul className={isOpen ? "main-menu active" : "main-menu"}>
            <li>
              <a href="/">
                Trang chủ <i className="fa-regular fa-angle-down"></i>
              </a>
              <ul className="sub-menu">
                <li className="subtwohober">
                  <Link to={`/category/1`}> <a href="index.html">Tinh dầu</a></Link>
                </li>
                <li className="subtwohober">
                  <Link to={`/category/2`} > <a href="index-light.html">Pod sạc</a></Link>
                </li>
                <li className="subtwohober">
                  <Link to={`/category/3`} > <a href="index-2.html">Pod 1 lần</a></Link>
                </li>
                <li className="subtwohober">
                  <Link to={`/category/4`} > <a href="index-2-light.html">Item linh tinh</a></Link>
                </li>
              </ul>
            </li>
            <li>
              <a >Thông tin thêm</a>
            </li>

            {/* <li>
              <a href="#0">
                Blog <i className="fa-regular fa-angle-down"></i>
              </a>
              <ul className="sub-menu">
                <li className="subtwohober">
                  <a href="blog.html">Blog Standard</a>
                </li>
                <li className="subtwohober">
                  <a href="blog-grid.html">Blog Grid</a>
                </li>
                <li className="subtwohober">
                  <a href="blog-list.html">Blog List</a>
                </li>
                <li className="subtwohober">
                  <a href="blog-single.html">Blog Single</a>
                </li>
              </ul>
            </li> */}
            <li>
              <a>Liên hệ</a>
            </li>
          </ul>
          <div className="shipping__item d-none d-sm-flex align-items-center">
            {/* <div className="menu__right d-flex align-items-center">
              <div className="thumb">
                <img src={picking}alt="Picking up" />
              </div>
              <div className="content">
                <p>Picking up?</p>
                <div className="items">
                  <select
                    className="form__select p-0"
                    value={store}
                    onChange={handleStoreChange}
                  >
                    <option value="1">Select Store</option>
                    <option value="2">Store One</option>
                    <option value="3">Store Two</option>
                    <option value="4">Store Three</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="menu__right d-flex align-items-center">
              <div className="thumb">
                <img src={shipping} alt="Free Shipping" />
              </div>
              <div className="content">
                <p>
                  Free Shipping <br />
                  on order <strong>over $100</strong>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
