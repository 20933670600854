import { Box, Snackbar, SnackbarOrigin } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { BaseResponse, isSuccess } from "../../api/base-response";
import { ChildProductDTO, getProductsByIdParent } from "../../api/child-product-api";
import { ParentProductDTO } from "../../api/parent-product-api";
import { formatNumberWithCommas } from "../../app-utils";
import banner from '../assets/images/banner/pod.webp';
import Footer from "../home/footer";
import Header from "../home/header";
import TopHeader from "../home/top-header";
import { CartManager } from "./cart-manager";
import { ItemCart } from "./item-in-cart";

import fire from '../assets/images/icon/fire.svg';
const PageBanner = (data: ParentProductDTO) => (
    <div className="swiper banner-two__slider">
        <div className="swiper-wrapper">
            <div className="swiper-slide">
                <div className="slide-bg transbox" style={{ background: `url(${banner})`, backgroundRepeat: "no-repeat", backgroundSize: "120%" }}></div>
                <section className="page-banner bg-image pt-130 pb-130" >
                    <div className="container">
                        <h2 className="wow fadeInUp mb-15" data-wow-duration="1.1s" data-wow-delay=".1s">
                            {data.name_product}
                        </h2>
                        <div className="breadcrumb-list wow fadeInUp" data-wow-duration="1.3s" data-wow-delay=".3s">
                            <Link style={{ fontSize: "18px" }} to={`/`} className="primary-hover">
                                <i className="fa-solid fa-house me-1"></i> Home <i className="fa-regular text-white fa-angle-right"></i>
                            </Link>
                            <Link style={{ fontSize: "18px" }} to={`/category/` + data.id_category} className="primary-hover">
                                {data.id_category == 1 ? "Tinh Dầu" : data.id_category == 2 ? "Pod Sạc" : data.id_category == 3 ? "Pod 1 Lần" : "Linh Ta Linh Tinh"} <i className="fa-regular text-white fa-angle-right"></i>
                            </Link>
                            <span style={{ fontSize: "18px" }}>{data.name_product}</span>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
)



const ShopSingle = () => {

    const { state } = useLocation();
    const { id_parent_product, name, price, technical_detail, made_in, product_detail, keywords, id_category } = state;
    const params = useParams();
    useEffect(() => {
        scroll()
    }, [])
    const scroll = () => {
        document.querySelector("#name")?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    };

    return (
        <div>
            <PageBanner id_parent_product={id_parent_product} price={price} technical_detail={technical_detail} name_product={name} made_in={made_in} product_detail={product_detail} keywords={keywords} rating={5} id_category={id_category} image={""} image1={""} />
            <section className="shop-single pt-20 pb-130">
                <div className="container">
                    <ProductDetails id_parent_product={id_parent_product} price={price} technical_detail={technical_detail} name_product={name} made_in={made_in} product_detail={product_detail} keywords={keywords} rating={5} id_category={id_category} />
                    <DescriptionReview data={product_detail} />
                </div>
            </section>
        </div>
    )
};
interface State extends SnackbarOrigin {
    open: boolean;
}
const ProductDetails = (props: any) => {
    const { id_parent_product, price, technical_detail, name_product, made_in, product_detail, keywords, rating, id_category } = props
    const [image, setChosenImage] = useState("")
    const [products, setProducts] = useState<ChildProductDTO[]>([])
    const [quantity, setQuantity] = useState(1);
    const [selectedFlavour, setSelectedFlavour] = useState("");
    const [selectedProduct, setSelectedProduct] = useState(new ChildProductDTO());
    const [discountCode, setDiscountCode] = useState("");
    const navigate = useNavigate();
    const [state, setState] = React.useState<State>({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;

    const handleClick = (newState: SnackbarOrigin) => () => {
        setState({ ...newState, open: true });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };
    const handleQuantityChange = (type: 'increment' | 'decrement') => {
        setQuantity(prevQuantity => {
            if (type === 'increment') {
                return prevQuantity + 1;
            } else if (type === 'decrement') {
                return prevQuantity > 1 ? prevQuantity - 1 : 1;
            }
            return prevQuantity;
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        if (!isNaN(value) && value >= 0) {
            setQuantity(value);
        }
    };
    useEffect(() => {
        fetchFavorite()
    }, [])

    useEffect(() => {
        fetchFavorite()
    }, [])
    useEffect(() => {
        if (products.length > 0) {
            setChosenImage("https://api.thegovape.com/" + products[0].image)
            setSelectedFlavour(products[0].flavour)
            setSelectedProduct(products[0])
        }
    }, [products])

    const fetchFavorite = async () => {
        getProductsByIdParent(parseInt(id_parent_product + ""))
            .then((res: BaseResponse<ChildProductDTO[]>) => {
                if (isSuccess(res)) {
                    setProducts(res.data)
                } else {

                }
            })
            .catch((error) => {
                console.log(error);

            });

    };

    const addItemToCart = () => {
        const cartManager = new CartManager();
        const newItem = new ItemCart();
        newItem.image = selectedProduct.image;
        newItem.name = `${name_product + " - " + (selectedProduct.flavour)}`;
        newItem.price = price;
        newItem.quantity = quantity;
        newItem.total = newItem.price * newItem.quantity;
        cartManager.addItem(newItem);

        setQuantity(1)
        setState({ ...{ vertical: 'bottom', horizontal: 'center' }, open: true });
    }

    const addItemToCartAndBuyNow = () => {
        const cartManager = new CartManager();
        const newItem = new ItemCart();
        newItem.image = selectedProduct.image;
        newItem.name = `${name_product + " - " + (selectedProduct.flavour)}`;
        newItem.price = price;
        newItem.quantity = quantity;
        newItem.total = newItem.price * newItem.quantity;
        cartManager.addItem(newItem);
        navigate("/cart")

    }

    return (
        <div className="product-details-single pb-40">
            <div className="row g-4">
                {/* --------------------- Ảnh ----------------------------- */}
                <div className="col-lg-5 pt-10">
                    <div className="">
                        <div >
                            <img style={{ objectFit: "cover", height: "100%", width: "100%" }} src={image} alt="product" className="main-image" />
                        </div>
                        <div style={{}} className="thumbnail-container">
                            {products.map((product, index) => (
                                <a >
                                    <img
                                        onClick={() => setChosenImage("https://api.thegovape.com/" + product.image)}
                                        style={{ height: 82, width: 82, marginTop: 16, marginRight: 8 }}
                                        key={index}
                                        src={"https://api.thegovape.com/" + product.image}
                                        alt={`product-thumbnail-${index}`}
                                        className={`thumbnail ${image === "https://api.thegovape.com/" + product.image ? 'purple-border' : ''}`}

                                    />
                                </a>
                            ))}
                            {/* <a>
                                <img

                                    style={{ objectFit: "cover", height: 82, width: 82, marginTop: 16, marginRight: 8 }}

                                    src={"https://api.thegovape.com/" + img}
                                // className={`thumbnail ${mainImage === image ? 'active' : ''}`}

                                />
                            </a> */}
                        </div>
                    </div>
                </div>
                {/* --------------------- Nội dung ----------------------------- */}
                <div className="col-lg-7">
                    <div className="content h24">
                        <h2 id="name" className="pb-2 primary-color pt-10">{name_product}</h2>
                        <h4 >
                            <img src={fire} alt="icon" /> {' '}
                            <span style={{ fontSize: "14px" }} className="primary-color">best seller</span>
                        </h4>
                        <h3 className="mr-10 mt-10"><del className="mr-10 mt-10">{formatNumberWithCommas(parseInt(price) + (price * 0.5))} ₫</del></h3> <h2 className="pb-3">{formatNumberWithCommas(price)}₫</h2>
                        <h4 className="pb-2 primary-color">Thông số kỹ thuật</h4>
                        <p className="text-justify mb-10 maxline5" dangerouslySetInnerHTML={{ __html: `${technical_detail}` }}>

                        </p>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="details-area">
                                    <div className="category flex-wrap mt-4 d-flex py-3 bor-top bor-bottom">
                                        <h4 className="pe-3">Danh mục :</h4>
                                        <Link to={`/category/` + id_category} className="primary-hover">{id_category == 1 ? "Tinh Dầu" : id_category == 2 ? "Pod Sạc" : id_category == 3 ? "Pod 1 Lần" : "Linh Ta Linh Tinh"}</Link>

                                    </div>
                                    <div className="category flex-wrap  d-flex py-3 bor-bottom">
                                        <h4 className="pe-3">Xuất sứ :</h4>
                                        <a className="primary-hover">{made_in}</a>
                                    </div>
                                    <div className="category flex-wrap  d-flex py-3">
                                        <h4 className="pe-3">Flavours :</h4>

                                        {products.map((product, index) => (
                                            <div className={selectedFlavour == product.flavour ? "cate-border-chosen  me-2 pe-2 ps-2 mb-2" : "cate-border  me-2 pe-2 ps-2 mb-2"} ><a onClick={() => { setSelectedProduct(product); setSelectedFlavour(product.flavour); setChosenImage("https://api.thegovape.com/" + product.image) }}>{product.flavour} </a></div>
                                        ))}
                                    </div>
                                    <div className="cart-wrp py-2 ">
                                        <div className="cart-quantity">
                                            <form id="myform" method="POST" className="quantity" action="#">
                                                <input
                                                    type="button"
                                                    value="-"
                                                    className="qtyminus minus  primary-hover "
                                                    onClick={() => handleQuantityChange('decrement')}
                                                />
                                                <input
                                                    type="text"
                                                    name="quantity"
                                                    value={quantity}
                                                    className="qty me-2 ms-2 "
                                                    onChange={handleInputChange}
                                                />
                                                <input
                                                    type="button"
                                                    value="+"
                                                    className="qtyplus plus  primary-hover "
                                                    onClick={() => handleQuantityChange('increment')}
                                                />
                                            </form>
                                        </div>
                                    </div>
                                    <a onClick={() => { addItemToCartAndBuyNow() }} className="d-block text-center btn-one  bor-bottom ">
                                        <span>
                                            <i className="fa-solid fa-basket-shopping pe-2"></i>
                                            Mua ngay
                                        </span>
                                    </a>
                                    <a onClick={() => { addItemToCart() }} className="d-block text-center btn-two  bor-bottom ">
                                        <span>
                                            <i className="fa-solid fa-basket-shopping pe-2"></i>
                                            Thêm vào giỏ hàng
                                        </span>
                                    </a>

                                    <div className="category flex-wrap  d-flex py-3  bor-top mt-20">
                                        <h4 className="pe-3">Từ khóa :</h4>
                                        <a className="primary-hover">{keywords}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Box sx={{ width: 700 }}>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={handleClose}
                    key={vertical + horizontal}
                >
                    <div className="px-3" style={{ borderRadius: 4, display: "flex", flexDirection: "column", background: "#28282b" }}><span>Thêm vào giỏ hàng thành công </span><a href='/cart' style={{ display: "flex", justifyContent: "center" }} className='primary-color mt-2'> Bấm vào đây để tới giỏ hàng và thanh toán</a></div></Snackbar>
            </Box>
        </div>
    );
}







const DescriptionReview = (props: any) => {
    const { data } = props
    return (
        <div className="shop-singe-tab">
            <ul className="nav nav-pills mb-4 bor-top bor-bottom py-2">
                <li className="nav-item">
                    <a href="#description" data-bs-toggle="tab" className="nav-link ps-0 pe-3 active">
                        <h4 className="text-uppercase">Chi tiết sản phẩm</h4>
                    </a>
                </li>
            </ul>
            <div className="tab-content">
                <div id="description" className="tab-pane fade show active">
                    <p className="pb-4 text-justify" dangerouslySetInnerHTML={{ __html: `${data}` }}>
                    </p>
                </div>
            </div>
        </div>
    );
}

const ShopDetails = () => (
    <div>
        <TopHeader />
        <Header />

        <main>

            <ShopSingle />
        </main>
        <Footer />
    </div>
);

export default ShopDetails;

