
export const ApiConfig = {
  API_URL: "https://api.thegovape.com",
  // API_HTTPS: 'https://',
  // PORT: 5000,
};

export const CategoryRouter = {

  get API_GET_CATEGORY() {
    return `categories/get-all`;
  }
  ,
  get API_GET_COMMENTS() {
    return `comments`;
  },
  get API_RAND_PRODUCTS() {
    return `products/random/9`;
  }
  ,
  get API_PRODUCTS_BY_CATEGORIES() {
    return `products/category`
  }
  ,
  get API_ALL_PARENT_PRODUCTS() {
    return `products/get-all`
  }
  ,
  API_PRODUCTS_DETAIL(id: Number) {
    return `products/${id}`
  },
  API_PRODUCTS_by_PARENT_ID(id: Number) {
    return `flavours/by-parent/${id}`
  },
  API_CREATE_ORDER() {
    return `orders/create`
  },
  get API_GET_ALL_ORDERS() {
    return `orders/get-all`
  }
};

