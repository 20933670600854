import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swiper, { Autoplay } from 'swiper';
import { BaseResponse, isSuccess } from '../../api/base-response';
import { getRandomProducts, ParentProductDTO } from '../../api/parent-product-api';
import { formatNumberWithCommas } from '../../app-utils';
import banner from '../assets/images/banner/pod.webp';
import fire from '../assets/images/icon/fire.svg';
import vape1 from '../assets/images/shape/vape1.png';
import vape2 from '../assets/images/shape/vape2.png';
const BannerArea = () => {
  const [categories, setCategories] = useState<ParentProductDTO[]>([])
  useEffect(() => {
    fetchFavorite()
  }, [])
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate()

  const fetchFavorite = async () => {
    getRandomProducts()
      .then((res: BaseResponse<ParentProductDTO[]>) => {
        if (isSuccess(res)) {
          setCategories(res.data)
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

  };
  const slide = useRef(new Swiper("", undefined))
  const nextButtonRef = useRef(null);

  useEffect(() => {
    slide.current = new Swiper('.testimonial__slider2', {
      slidesPerView: 1,
      spaceBetween: 1,
      navigation: {
        nextEl: '.testimonial__arry-next',
        prevEl: '.testimonial__arry-prev',
      },
      speed: 800,

      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        stopOnLastSlide: false,
      },
      modules: [Autoplay]
    });
    // Automatically trigger the "Next" button every 2 seconds

  }, []);

  return (

    <section className=" banner-two">

      <div id="preloader" className={isLoading ? '' : 'isdone'}>
      </div>
      <div className={`pace ${isLoading ? '' : 'pace-inactive'}`}>
        <div className="pace-progress"></div>
      </div>
      <div className="mouse-cursor cursor-outer"></div>
      <div className="mouse-cursor cursor-inner"></div>
      <div className="banner-two__shape-left d-none d-lg-block">
        <img src={vape1} alt="shape" />

      </div>
      <div className="banner-two__shape-right d-none d-lg-block">
        <img className="sway_Y__animation" src={vape2} alt="shape" />
      </div>
      {/* <div className="swiper banner-two__slider">
          <div className="swiper-wrapper"> */}
      {/* Slide 1 */}

      {/* </div>
        </div> */}
      <div className="swiper banner-two__slider">
        <div className="swiper-wrapper">
          <div className="swiper-slide pb-120">
            <div className="slide-bg transbox" style={{ background: `url(${banner})`, backgroundRepeat: "no-repeat", height: "6000%", backgroundSize: "120%" }}></div>
            <div className="container ">
              <div className="swiper testimonial__slider2">

                <div className="swiper-wrapper ">
                  {categories.map((testimonial, index) => (
                    <div className="swiper-slide" key={index}>
                      <div className='banner-direction' style={{ marginLeft: "0px" }}>

                        <div style={{}} className="banner-two__content">
                          <h4>
                            <img src={fire} alt="icon" /> {' '}
                            <span className="primary-color">Sản phẩm đang giảm giá</span>
                          </h4>
                          <div className="banner-direction">
                            <div className='banner mr-80' onClick={() => { navigate(`/product-detail/${testimonial.id_parent_product}`, { state: { id_category: testimonial.id_category, id_parent_product: testimonial.id_parent_product, price: testimonial.price, name: testimonial.name_product, product_detail: testimonial.product_detail, made_in: testimonial.made_in, technical_detail: testimonial.technical_detail, keywords: testimonial.keywords } }) }}><img style={{ width: "100%", height: "500px", maxWidth: "500px" }} src={"https://api.thegovape.com/" + testimonial.image} alt="icon" /></div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <h2 onClick={() => { navigate(`/product-detail/${testimonial.id_parent_product}`, { state: { id_category: testimonial.id_category, id_parent_product: testimonial.id_parent_product, price: testimonial.price, name: testimonial.name_product, product_detail: testimonial.product_detail, made_in: testimonial.made_in, technical_detail: testimonial.technical_detail, keywords: testimonial.keywords } }) }}>
                                {testimonial.name_product}
                              </h2>
                              <span className="" dangerouslySetInnerHTML={{ __html: `${testimonial.technical_detail}` }}>

                              </span>
                              <div className="banner-two__info mt-30">
                                <span style={{ fontSize: "18px" }} className="mb-10">Giảm giá hôm nay</span>
                                <h3>  <del className="mr-10">{formatNumberWithCommas(parseInt(testimonial.price) + (parseInt(testimonial.price) * 0.2))} ₫</del></h3>
                                <h2 style={{}}>  {formatNumberWithCommas(parseInt(testimonial.price))} ₫</h2>
                              </div>
                              <div className="btn-wrp">
                                {/* <a className="btn-one">
                            <span>Direct IG <MDBIcon className='me-2' fab icon='instagram' /></span>
                          </a> */}
                                <div onClick={() => { navigate(`/product-detail/${testimonial.id_parent_product}`, { state: { id_category: testimonial.id_category, id_parent_product: testimonial.id_parent_product, price: testimonial.price, name: testimonial.name_product, product_detail: testimonial.product_detail, made_in: testimonial.made_in, technical_detail: testimonial.technical_detail, keywords: testimonial.keywords } }) }} >
                                  <a className="btn-one-light ">
                                    <span>Xem chi tiết</span>
                                  </a>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="banner-two__arry-btn">
        <button onClick={() => { slide.current.slidePrev(); }} className="arry-prev mb-15 banner-two__arry-prev">
          <i className="fa-light fa-chevron-left"></i>
        </button>
        <button onClick={() => { slide.current.slideNext(); }} className="arry-next active banner-two__arry-next">
          <i className="fa-light fa-chevron-right"></i>
        </button>
      </div>
    </section>
  );
}


export default BannerArea;
