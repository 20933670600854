import { ItemCart } from "./item-in-cart";

export class CartManager {
  private storageKey = "cartItems";

  // Retrieve the cart from local storage or initialize an empty list
  private getCartItems(): ItemCart[] {
    const storedItems = localStorage.getItem(this.storageKey);
    return storedItems ? JSON.parse(storedItems) : [];
  }

  // Save the cart to local storage
  private saveCartItems(cartItems: ItemCart[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(cartItems));
  }

  // Convert getItems to an async function that returns a Promise
  async getItems(): Promise<ItemCart[]> {
    return new Promise((resolve) => {
      const items = this.getCartItems();
      resolve(items);
    });
  }

  // Add an item to the cart
  addItem(newItem: ItemCart): void {
    const cartItems = this.getCartItems();
    cartItems.push(newItem);
    this.saveCartItems(cartItems);
  }

  // Remove an item from the cart by index
  removeItem(index: number): void {
    const cartItems = this.getCartItems();
    if (index >= 0 && index < cartItems.length) {
      cartItems.splice(index, 1);
      this.saveCartItems(cartItems);
    }
  }

  // Edit an item in the cart by index
  editItem(index: number, updatedItem: ItemCart): void {
    const cartItems = this.getCartItems();
    if (index >= 0 && index < cartItems.length) {
      cartItems[index] = updatedItem;
      this.saveCartItems(cartItems);
    }
  }
 // Edit the quantity of an item in the cart by index
 editItemQuantity(index: number, newQuantity: number): void {
  const cartItems = this.getCartItems();
  if (index >= 0 && index < cartItems.length) {
    cartItems[index].quantity = newQuantity;
    cartItems[index].total = cartItems[index].price * newQuantity;
    this.saveCartItems(cartItems); // Save updated cart to storage
  } else {
    console.error("Index out of bounds");
  }
}
  // Clear all items from the cart
  clearCart(): void {
    localStorage.removeItem(this.storageKey);
  }
}
