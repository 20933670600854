import { AxiosResponse } from "axios";
import { CategoryRouter } from "./api-config";
import axiosClient from "./axios-client";
import { BaseResponse } from "./base-response";

export class ParentProductDTO {
    id_parent_product: number = 0;
    name_product: string = "";
    product_detail: string = "";
    technical_detail: string = "";
    made_in: string = "";
    keywords: string = "";
    price: string = "";
    rating: number = 0;
    image: string = "";
    image1: string = "";
    id_category: number =0;
  }
  

export const getRandomProducts = async (): Promise<BaseResponse<ParentProductDTO[]>> => {
    try {
        const url = CategoryRouter.API_RAND_PRODUCTS;
        const response: AxiosResponse<BaseResponse<ParentProductDTO[]>> = await axiosClient.get(url, {

        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getAllParentProducts = async (): Promise<BaseResponse<ParentProductDTO[]>> => {
    try {
        const url = CategoryRouter.API_ALL_PARENT_PRODUCTS;
        const response: AxiosResponse<BaseResponse<ParentProductDTO[]>> = await axiosClient.get(url, {

        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getProductsByCategoryWithPagination = async (
    categoryId: number, 
    page: number = 1, 
    limit: number = 10
): Promise<BaseResponse<{ products: ParentProductDTO[], total: number }>> => {
    try {
        const url = `${CategoryRouter.API_PRODUCTS_BY_CATEGORIES}/${categoryId}?page=${page}&limit=${limit}`;
        const response: AxiosResponse<BaseResponse<{ products: ParentProductDTO[], total: number }>> = await axiosClient.get(url, { 
            // Additional config options can be added here if needed
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
export const getProductDetail = async (
    id: number
): Promise<BaseResponse<{ products: ParentProductDTO }>> => {
    try {
        const url = `${CategoryRouter.API_PRODUCTS_DETAIL(id)}`;
        const response: AxiosResponse<BaseResponse<{ products: ParentProductDTO}>> = await axiosClient.get(url, { 
            // Additional config options can be added here if needed
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};