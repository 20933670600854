import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swiper from 'swiper';
import { ApiConfig } from '../../api/api-config';
import { BaseResponse, isSuccess } from '../../api/base-response';
import { CategoryDTO, getCategoryList } from '../../api/category-api';

const CategoryArea = () => {
  const [isScrollable, setScrollable] = useState(true)

  const [categories, setCategories] = useState<CategoryDTO[]>([])
  useEffect(() => {
    fetchFavorite()
  }, [])


  const fetchFavorite = async () => {
    getCategoryList()
      .then((res: BaseResponse<CategoryDTO[]>) => {
        if (isSuccess(res)) {
          setCategories(res.data)
        } else {

        }
      })
      .catch((error) => {
        console.log(error);

      });

  };

  useEffect(() => {
    new Swiper('', {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 10,
      allowTouchMove: false
      ,
      shortSwipes: false
      ,
      allowSlideNext: false
      ,
      allowSlidePrev: false
      ,
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,

        },
      },
    });
  }, []);
  const navigate = useNavigate();
  return (
    <section className="category-area category-two pt-100">
      <div className="container">
        <div >
          <div className="sub-title text-center mb-20">
            <h3>
              <span className="title-icon"></span> Full topping không hành <span className="title-icon"></span>
            </h3>
          </div>
          <div className="flex items-stretch md:items-center  ml-80 mr-80 ">
            <div className="swiper-wrapper flex-container  " style={{ display: "flex", justifyContent: "space-between" }}>
              {categories.map((cat, index) => (
                 <Link to={`/category/${cat.id_category}`} className="mt-30 flex items-stretch md:items-center" key={index}>
                  <div className="category__item category-two__item text-center">
                    <a href={""} className="category__image d-block">
                      <img src={ApiConfig.API_URL + "/public/category-image" + (index + 1) + ".png"} alt="image" />
                      <div className="category-icon">
                        <img src={ApiConfig.API_URL + "/public/category-icon" + (index + 1) + ".png"} alt="icon" />
                      </div>
                    </a>
                    <h4 className="mt-30">
                      <a href={""}>{cat.name_category}</a>
                    </h4>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoryArea;
