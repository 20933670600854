import { useEffect, useRef, useState } from 'react';
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.min.css';
import { BaseResponse, isSuccess } from '../../api/base-response';
import { CommentDTO, getCommentList } from '../../api/comment-api';
import customer from '../assets/images/testimonial/testimonial1.png';

const CustomerSection = () => {

    const [comment, setComment] = useState<CommentDTO[]>([])
    useEffect(() => {
        fetchFavorite()
    }, [])
    const slide = useRef(new Swiper("", undefined))
    const nextButtonRef = useRef(null);

    const fetchFavorite = async () => {
        getCommentList()
            .then((res: BaseResponse<CommentDTO[]>) => {
                if (isSuccess(res)) {
                    setComment(res.data)
                } else {

                }
            })
            .catch((error) => {
                console.log(error);

            });

    };

    useEffect(() => {
        slide.current = new Swiper('.testimonial__slider', {
            slidesPerView: 1,
            spaceBetween: 1,
            navigation: {
                nextEl: '.testimonial__arry-next',
                prevEl: '.testimonial__arry-prev',
            },
            autoplay: {
                delay: 2000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
                stopOnLastSlide: false,
                waitForTransition: true
            },
            modules: [Autoplay]
        });
        // Automatically trigger the "Next" button every 2 seconds

    }, []);
    return (
        <section className="testimonial pt-130 pb-130">
            <div className="container">
                <div className="testimonial__wrp bor radius-10">
                    <div className="testimonial__head-wrp bor-bottom pb-65 pt-65 pl-65 pr-65">
                        <div className="section-header d-flex align-items-center wow fadeInUp" data-wow-delay=".1s">
                            <span className="title-icon mr-10"></span>
                        </div>
                        <h3>{`Khách hàng feedback <3`}</h3>
                        <div className="section-header d-flex align-items-center wow fadeInUp" data-wow-delay=".1s">
                            <span className="title-icon mr-10"></span>
                        </div>
                        <div className="arry-btn my-4 my-lg-0">
                            <button
                                onClick={() => { slide.current.slidePrev(); }}
                                className="arry-prev testimonial__arry-prev wow fadeInUp"
                                data-wow-delay=".2s"
                            >
                                <i className="fa-light fa-chevron-left"></i>
                            </button>
                            <button
                                onClick={() => { slide.current.slideNext(); }}
                                className="ms-3 active arry-next testimonial__arry-next wow fadeInUp"
                                data-wow-delay=".3s"
                            >
                                <i className="fa-light fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                    <div className="pt-45 pb-45 pr-55">
                        <div className="swiper testimonial__slider">
                            <div className="swiper-wrapper ">
                                {comment.map((testimonial, index) => (
                                    <div className="swiper-slide" key={index}>
                                        <div
                                            style={{ justifyContent: "space-between", display: "flex" }}
                                            className="testimonial__item d-flex align-items-center pl-65"
                                        >
                                            <div className="testimonial__content">
                                                <div className="testi-header mb-30">
                                                    <div className="testi-content">
                                                        <h3>{testimonial.customer_name}</h3>
                                                        <span>Khách hàng</span>
                                                    </div>
                                                    <i className="fa-solid fa-quote-right"></i>
                                                </div>
                                                <p>{testimonial.content}</p>
                                                <div className="star mt-30">
                                                    {Array.from({ length: testimonial.star }).map((_, i) => (
                                                        <i key={i} className="fa-solid fa-star"></i>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="testimonial__image m-4">
                                                <img src={customer} alt={`Testimonial from ${testimonial.customer_name}`} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CustomerSection;
