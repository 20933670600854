import viewImage from '../assets/images/view/view-image1.jpg';
import background from '../assets/images/bg/view-bg.jpg';
import viewImage2 from '../assets/images/view/view-image2.jpg';
import viewImage3 from '../assets/images/view/view-image3.jpg';
import bg1 from '../assets/images/bg/discount-bg2.jpg';
import bg2 from '../assets/images/category/category-image4.png';
import bg3 from '../assets/images/category/category-image5.png';
const ViewArea = () => {
  return (
    <section className="view-area ">
      <div className="bg-image view__bg slide-bg transbox" style={{ backgroundImage: `url(${background})` }}></div>
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-6 wow mt-200 fadeInLeft" data-wow-delay=".1s">
            <div className="view__left-item">
              <div className="image">
                <img src={bg1} alt="image" />
              </div>
              <div className=" view__left-content sub-bg">
                <h3>OUR DISCLAIMER</h3>
                <span className="mb-40">
                  Hút vape làm người dùng tiếp xúc với các tác dụng độc hại và gây nghiện của nicotine và không nên được sử dụng bởi trẻ em, phụ nữ đang mang thai hoặc cho con bú, hoặc những người có nguy cơ mắc bệnh tim, cao huyết áp, tiểu đường hoặc đang dùng thuốc điều trị trầm cảm hoặc hen suyễn.
                </span>

              </div>
            </div>
          </div>
          <div className="mt-200 col-lg-6">
            <div className="view__item mb-10 wow fadeInDown" data-wow-delay=".2s">
              <div className="view__content">
                <h3><a className="primary-hover" href="shop-single.html">Lần đầu sử dụng?</a></h3>
                <span>Hướng dũng sử dận cho mấy newbie</span>
              </div>
              <div className="view__image">
                <img src={bg2} alt="image" />
              </div>
            </div>
            <div className="view__item mb-25 wow fadeInDown" data-wow-delay=".2s">
              <div className="view__content">
                <h3><a className="primary-hover" href="shop-single.html">Buy and refund</a></h3>
                <span>Cách mua hàng và hoàn trả của Sốp</span>
              </div>
              <div className="view__image">
                <img src={bg3} alt="image" />
              </div>
            </div>
            {/* <div className="view__item wow fadeInUp" data-wow-delay=".3s">
              <div className="view__content">
                <h3><a className="primary-hover" href="shop-single.html">Vap mode</a></h3>
                <p>Whereas recognition of the inherent dignity</p>
              </div>
              <div className="view__image">
                <img src={viewImage3} alt="image" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewArea;
