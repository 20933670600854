import { AxiosResponse } from "axios";
import { CategoryRouter } from "./api-config";
import axiosClient from "./axios-client";
import { BaseResponse } from "./base-response";

export class CommentDTO {
    id_comment: number = 0;
    customer_name: string = "";
    content: string = "";
    img: string = "";
    star: number = 0;
}

export const getCommentList = async (): Promise<BaseResponse<CommentDTO>> => {
    try {
        const url = CategoryRouter.API_GET_COMMENTS;
        const response: AxiosResponse<BaseResponse<CommentDTO>> = await axiosClient.get(url, {

        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};