
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { BaseResponse, isSuccess } from "../../api/base-response";
import { getProductsByCategoryWithPagination, ParentProductDTO } from "../../api/parent-product-api";
import countdown from '../assets/images/coundown/coundown-image1.jpg';
import Footer from "../home/footer";
import Header from "../home/header";
import Product from "../home/product";
import TopHeader from "../home/top-header";

import banner from '../assets/images/banner/pod.webp';
const Banner = () => {
    const params = useParams();
    const navigate = useNavigate();
    var id = parseInt(params.id + "")
    return (
        <div className="swiper banner-two__slider">
            <div className="swiper-wrapper">
                <div className="swiper-slide">
                    <div className="slide-bg transbox" style={{ background: `url(${banner})`, backgroundRepeat: "no-repeat", backgroundSize: "120%" }}></div>
                    <section className="page-banner bg-image pt-130 pb-130" >
                        <div className="container">
                            <h2 className="wow fadeInUp mb-15" data-wow-duration="1.1s" data-wow-delay=".1s">
                                {id == 1 ? "Tinh Dầu" : id == 2 ? "Pod Sạc" : id == 3 ? "Pod 1 Lần" : "Linh Ta Linh Tinh"}
                            </h2>
                            <div className="breadcrumb-list wow fadeInUp" data-wow-duration="1.3s" data-wow-delay=".3s">
                                <Link style={{ fontSize: "18px" }} to={`/`} className="primary-hover">
                                    <i className="fa-solid fa-house me-1"></i> Home <i className="fa-regular text-white fa-angle-right"></i>
                                </Link>
                                <span style={{ fontSize: "18px" }}>{id == 1 ? "Tinh Dầu" : id == 2 ? "Pod Sạc" : id == 3 ? "Pod 1 Lần" : "Linh Ta Linh Tinh"}</span>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}


const Products = () => {
    const [products, setProducts] = useState<ParentProductDTO[]>([])
    const currentPage = useRef(1)
    const [total, setTotal] = useState(0)
    const limit = 8
    const params = useParams();
    useEffect(() => {
        fetchFavorite()
        scroll()
    }, [params])


    const fetchFavorite = async () => {
        getProductsByCategoryWithPagination(parseInt(params.id + ""), currentPage.current, limit)
            .then((res: BaseResponse<{ ParentProducts: ParentProductDTO[], total: number }>) => {
                if (isSuccess(res)) {
                    setProducts(res.data.ParentProducts)
                    setTotal(res.data.total)
                } else {

                }
            })
            .catch((error) => {
                console.log(error);

            });

    };

    const totalPages = Math.ceil(total / limit);
    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            currentPage.current = page
            fetchFavorite()
        }
    };
    const scroll = () => {
        document.querySelector("#products")?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        // logger.log("#"+categories.getCategoryId(name))
    };
    return (
        <section className="product-area pt-20  pb-130">
            <div id="products" className="container">
                <div className="pb-20 bor-bottom shop-page-wrp d-flex justify-content-between align-items-center mb-65">
                    <p className="fw-600">Hiện có {total} sản phẩm.</p>
                    <div className="short">
                        <select name="shortList" id="shortList">
                            <option value="0">Short by popularity</option>
                            {/* More options here */}
                        </select>
                    </div>
                </div>
                <div className="row g-4">
                    <div className="col-xl-9 col-lg-8">

                        {
                            products.length > 0 ? (
                                <div>
                                    <div className="row g-4">
                                        {products.map((product, index) => (
                                            <Product num={product} />
                                        ))}
                                    </div>
                                    <div className="pagi-wrp mt-65">
                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <a
                                                href="#0"
                                                key={index + 1}
                                                style={{ marginRight: 8 }}
                                                className={`pagi-btn ${currentPage.current === index + 1 ? 'active' : ''}`}
                                                onClick={() => { scroll(); handlePageChange(index + 1) }}
                                            >
                                                {index + 1}
                                            </a>
                                        ))}
                                        <a
                                            href="#0"
                                            className={`fa-regular ms-2 primary-hover fa-angle-right ${currentPage.current < totalPages ? '' : 'disabled'}`}
                                            onClick={() => { scroll(); handlePageChange(currentPage.current + 1) }}
                                        ></a>
                                    </div>
                                </div>
                            ) : <h3>Chưa có sản phẩm phù hợp</h3>
                        }
                    </div>
                    <div className="col-xl-3 col-lg-4">

                        <div className="product__left-item sub-bg  " style={{ background: `url(${countdown})`, backgroundSize: "100%", backgroundRepeat: "no-repeat" }}>
                            <h3 className="mb-30 ">Sale sập sàn</h3>
                            <h4 style={{ lineHeight: 2 }}>Chưa có sản phẩm sale hôm nay </h4>
                            {/* <div className="image mb-30">
                                <img src="assets/images/coundown/coundown-image1.png" alt="image" />
                            </div>

                            <div className="product__content p-0">
                                <h4 className="mb-15">
                                    <a className="primary-hover" href="shop-single.html">Mango Nic Salt E-Liquid</a>
                                </h4>
                                <del>$74.50</del>
                                <span className="primary-color ml-10">$49.50</span>
                                <div className="star mt-20">
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                </div>
                            </div>

                            <div className="image pt-40 mb-30 bor-top mt-40">
                                <img src="assets/images/coundown/coundown-image2.png" alt="image" />
                            </div>

                            <div className="product__content p-0">
                                <h4 className="mb-15">
                                    <a className="primary-hover" href="shop-single.html">Watermelon Nic Salt</a>
                                </h4>
                                <del>$74.50</del>
                                <span className="primary-color ml-10">$49.50</span>
                                <div className="star mt-20">
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                </div>
                            </div> */}

                            {/* <div className="product__coundown pt-30 bor-top mt-40">
                                <h4>Hurry Up!</h4>
                                <span>offer ends in</span>
                                <div className="d-flex align-items-center gap-3 flex-wrap mt-25">
                                    <div className="coundown-item">
                                        <span id="day"></span>
                                        <h6>Day</h6>
                                    </div>
                                    <div className="coundown-item">
                                        <span id="hour"></span>
                                        <h6>Hour</h6>
                                    </div>
                                    <div className="coundown-item">
                                        <span id="min"></span>
                                        <h6>Min</h6>
                                    </div>
                                    <div className="coundown-item">
                                        <span id="sec"></span>
                                        <h6>Sec</h6>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

const Shop = () => (
    <div>
        <TopHeader />
        <Header />
        <main>
            <Banner />

            <Products />

        </main>
        <Footer />

    </div>
);

export default Shop;
