import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Import tailwind styles
import "./feature/assets/css/all.min.css";
import "./feature/assets/css/animate.css";
import "./feature/assets/css/bootstrap.min.css";
import "./feature/assets/css/magnific-popup.css";
import "./feature/assets/css/nice-select.css";
import "./feature/assets/css/style.css";
import "./feature/assets/css/style.css.map";
import "./feature/assets/css/swiper-bundle.min.css";


import './feature/assets/css/_progress.scss'
import './feature/assets/css/_animation.scss'
import './feature/assets/css/_buttons.scss'
import './feature/assets/css/_footer.scss'
import './feature/assets/css/_header.scss'
import './feature/assets/css/_mixins.scss'
import './feature/assets/css/_responsive.scss'
import './feature/assets/css/_section.scss'
import './feature/assets/css/_title.scss'
import './feature/assets/css/_typography.scss'
import './feature/assets/css/_variables.scss'
import './feature/assets/css/style.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
