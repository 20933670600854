import { AxiosResponse } from "axios";
import { CategoryRouter } from "./api-config";
import axiosClient from "./axios-client";
import { BaseResponse } from "./base-response";

export class Order {
    id_order: number = 0;
    name_customer: string = "";
    address_customer: string = "";
    email_customer: string = "";
    created_date: string = "";
    is_paid: number = 0;
    order_detail: string = "";
    phone: string = "";
    note: string = ""
}

export class OrderToPush {
    name_customer: string = "";
    address_customer: string = "";
    email_customer: string = "";
    created_date: string = "";
    is_paid: number = 0;
    order_detail: string = "";
    phone: string = "";
    note: string = ""
}

export const createOrder = async (
    orderData: Partial<Order>
): Promise<BaseResponse<Order>> => {
    try {
        const url = CategoryRouter.API_CREATE_ORDER() // The endpoint for creating an order
        const response: AxiosResponse<BaseResponse<Order>> = await axiosClient.post(url, orderData, {
            // Additional config options can be added here if needed
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
export const getOrdersByCategoryWithPagination = async (
    page: number = 1,
    limit: number = 10
): Promise<BaseResponse<{ products: Order[], total: number }>> => {
    try {
        const url = `${CategoryRouter.API_GET_ALL_ORDERS}?page=${page}&limit=${limit}`;
        const response: AxiosResponse<BaseResponse<{ products: Order[], total: number }>> = await axiosClient.get(url, {
            // Additional config options can be added here if needed
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
