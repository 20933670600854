import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatNumberWithCommas } from '../../app-utils';
import logo from '../assets/images/logo/logo.svg';
import { CartManager } from '../shop/cart-manager';
import { ItemCart } from '../shop/item-in-cart';
const TopHeader = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [country, setCountry] = useState('0');
  const [cart, setCart] = useState({ total: 0, itemCount: 0 });

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleCountryChange = (e: any) => {
    setCountry(e.target.value);
  };

  const handleSearchSubmit = (e: any) => {
    e.preventDefault();
    // Handle search logic here
  };
  const [cartItems, setCartItems] = useState<ItemCart[]>([]);
  const cartManager = new CartManager();

  useEffect(() => {
    const fetchCartItems = async () => {
      const items = await cartManager.getItems();
      setCartItems(items);
    };
    fetchCartItems();
  }, []); // Empty dependency array means this effect runs once on component mount

  return (
    <div className="top__header mt-10 pt-30 ">
      <div className="container">
        <div style={{ display: "flex", flexDirection: "row" }} className="top__wrapper" >
          <Link to={`/`} className="main__logo">
            <img src={logo} alt="logo__image" />
          </Link>
          {/* <div className="search__wrp">
            <form onSubmit={handleSearchSubmit}>
              <input
                placeholder="Search for"
                aria-label="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <button type="submit">
                <i className="fa-solid fa-search"></i>
              </button>
            </form>
          </div> */}
          <div style={{ display: "flex", flexGrow: 1 }} />

          <div className="account__wrap">

            {/* <a style={{ padding: "8px 16px", marginTop: "24px", borderRadius: "8px" }} className="instagram" href='#'>
              <MDBIcon className='me-2' fab icon='instagram' /> TheGoVape
            </a> */}
            {/* <div className="cart d-flex align-items-center">
              <span className="cart__icon">
                <i className="fa-regular fa-cart-shopping"></i>
              </span>
              <a href="#0" className="c__one">
                <span>${cart.total.toFixed(2)}</span>
              </a>
              <span className="one">{cart.itemCount}</span>
            </div>
             */}
            <a href='/cart' className="cart d-flex align-items-center">
              <span className="cart__icon">
                <i className="fa-regular fa-cart-shopping"></i>
              </span>
              <a className="c__one">
                <span style={{ width: "50px", fontSize: "14px" }} className="text-black">
                  {formatNumberWithCommas(cartItems.reduce((total, item) => total + item.total, 0))}
                </span>
              </a>
              <span className="one">
                {cartItems.length}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
