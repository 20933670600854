
import Banner from "./banner-area";
import CategoryArea from "./category-area";
import Footer from "./footer";
import Header from "./header";
import TopHeader from "./top-header";
import ViewArea from "./view-area";

import { useEffect, useState } from "react";
import '../assets/css/_animation.scss';
import '../assets/css/_buttons.scss';
import '../assets/css/_footer.scss';
import '../assets/css/_header.scss';
import '../assets/css/_mixins.scss';
import '../assets/css/_progress.scss';
import '../assets/css/_responsive.scss';
import '../assets/css/_section.scss';
import '../assets/css/_title.scss';
import '../assets/css/_typography.scss';
import '../assets/css/_variables.scss';
import '../assets/css/style.scss';
import CustomerSection from './CustomerSection';
import ProductArea from "./product-area";


import banner from '../assets/images/banner/pod.webp';

const Home = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading time with a timeout
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 500); // Adjust the duration as needed

        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            <section className=" view-area">
                <div className="slide-bg transbox bg-image view__bg" style={{ backgroundColor:"#121212" }}></div>
                <TopHeader />
                <Header />


                <main>

                    <Banner />
                    <CategoryArea />
                    <ProductArea />
                    <ViewArea />
                    <CustomerSection />
                    {/* <DiscountArea /> */}
                    {/* <GetNowArea /> */}
                    {/* <TextSlider /> */}
                    {/* <Gallery /> */}
                </main>
                <Footer />
                <div className="scroll-up">
                    <svg className="scroll-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                    </svg>
                </div>
                </section>

        </div>

    );
};

export default Home;
