import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatNumberWithCommas } from "../../app-utils";

import fire from '../assets/images/icon/fire.svg';
const Product = (props: any) => {
    const { num } = props
    const [showImage, setShowImage] = useState(false);
    const [thumbnailImage, setThumbnailImage] = useState<string>('');
    const [thumbnailImage1, setThumbnailImage1] = useState<string>('');
    const navigate = useNavigate()
    useEffect(() => {
        const fetchThumbnailImage = async () => {
            try {
                // if (item.restaurant_brand_avatar.startsWith('https://') || item.restaurant_brand_avatar.startsWith('http://')) {
                //   setThumbnailImage(item.restaurant_brand_avatar);
                // } else {
                //   if (item.restaurant_brand_avatar === '') {
                //     setThumbnailImage('');
                //   } else {
                //     const thumbnailImage = getLinkPhoto(item.restaurant_brand_avatar);
                //     setThumbnailImage(thumbnailImage);
                //   }
                // }
                setThumbnailImage("https://api.thegovape.com/" + num.image);
                setThumbnailImage1("https://api.thegovape.com/" + num.image1);
            } catch (error) {

            }
        };


        fetchThumbnailImage();
    }, [num.image]);

    return (
        <div key={num.id_category} className="col-xxl-3 col-xl-4 col-md-6">

            <div className="product__item bor" >
                <div onClick={() => { navigate(`/product-detail/${num.id_parent_product}`, { state: { id_category: num.id_category, id_parent_product: num.id_parent_product, price: num.price, name: num.name_product, product_detail: num.product_detail, made_in: num.made_in, technical_detail: num.technical_detail, keywords: num.keywords } }) }} className="product__image pt-20 d-block">
                    <img className="font-image" src={thumbnailImage} alt="image" />
                    <img className="back-image" src={thumbnailImage1} alt="image" />
                </div>
                <div className="product__content" onMouseEnter={() => setShowImage(true)}
                    onMouseLeave={() => setShowImage(false)} >
                    <h4 >
                        <img style={{ width: "12px" }} src={fire} alt="icon" /> {' '}
                        <span style={{ fontSize: "12px" }} className="primary-color">giảm giá</span>
                    </h4>
                    <h4 className="mt-10"><div onClick={() => { navigate(`/product-detail/${num.id_parent_product}`, { state: { id_category: num.id_category, id_parent_product: num.id_parent_product, price: num.price, name: num.name_product, product_detail: num.product_detail, made_in: num.made_in, technical_detail: num.technical_detail, keywords: num.keywords } }) }} className="primary-hover maxline2">{num.name_product}</div></h4>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <del className="mt-2 mr-10">{formatNumberWithCommas(parseInt(num.price) + (num.price * 0.2))} ₫</del>
                        <span style={{ fontSize: "22px", color: "#eb3678" }} className="mt-2 primary-color">{formatNumberWithCommas(num.price)} ₫</span>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default Product;