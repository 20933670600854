import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiConfig } from "./api-config";

const axiosClient = axios.create({
  baseURL: ApiConfig.API_URL,
  headers: {
    "content-type": "application/json",
  },
  responseType: "json",
  timeout: 30000
});


export default axiosClient;