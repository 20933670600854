import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
// Define the ProductDTO interface
interface ProductDTO {
  name_product: string;
  product_detail: string;
  technical_detail: string;
  made_in: string;
  keywords: string;
  price: string;
  rating: number;
  image: string;
  image1: string;
  id_category: number;
}

const ParentProductForm = () => {
  const [product, setProduct] = useState<ProductDTO>({
    name_product: '',
    product_detail: '',
    technical_detail: '',
    made_in: '',
    keywords: '',
    price: '',
    rating: 0,
    image: '',
    image1: '',
    id_category: 1,
  });
  const myColors = [
    "purple",
    "#785412",
    "#452632",
    "#856325",
    "#963254",
    "#254563",
    "white"
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: myColors }],
      [{ background: myColors }]
    ]
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align"
  ];
  const [selectedFile1, setSelectedFile1] = useState<File | null>(null);
  const [selectedFile2, setSelectedFile2] = useState<File | null>(null);
  const [imagePreview1, setImagePreview1] = useState<string | null>(null);
  const [imagePreview2, setImagePreview2] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // Handle input changes for the form fields
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle file input change for image 1
  const handleFileChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedFile1(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          setImagePreview1(reader.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle file input change for image 2
  const handleFileChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedFile2(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          setImagePreview2(reader.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Upload image to the server and retrieve link
  // const handleFileUpload = async (file: File | null, fieldName: 'image' | 'image1') => {
  //   if (!file) return;

  //   const formData = new FormData();
  //   formData.append('file', file);

  //   try {
  //     const response = await axios.post('https://api.thegovape.com/files/upload', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });

  //     setProduct((prevState) => ({
  //       ...prevState,
  //       [fieldName]: response.data.path, // Set image path after successful upload
  //     }));
  //   } catch (error) {
  //     console.error(`Error uploading ${fieldName}:`, error);
  //   }
  // };
  const handleFileUpload = async (file: File | null, fieldName: 'image' | 'image1'): Promise<boolean> => {
    if (!file) return false;

    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);
    try {
      const response = await axios.post('https://api.thegovape.com/files/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setProduct((prevState) => ({
        ...prevState,
        [fieldName]: response.data.path,
      }));
      if (fieldName == "image")
        product.image = response.data.path
      else
        product.image1 = response.data.path
      return true
    } catch (error) {
      console.error('Error uploading image:', error);

      return false
    } finally {
      setLoading(false);
    }
  };
  const handleQuillChange = (value: string, field: 'product_detail' | 'technical_detail') => {
    setProduct((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // Upload both images before submitting product
    await handleFileUpload(selectedFile1, 'image');
    await handleFileUpload(selectedFile2, 'image1');

    try {
      const response = await axios.post('https://api.thegovape.com/products', product);
      console.log('Product created successfully:', response.data);

      // Reset the form on success
      setProduct({
        name_product: '',
        product_detail: '',
        technical_detail: '',
        made_in: '',
        keywords: '',
        price: '',
        rating: 0,
        image: '',
        image1: '',
        id_category: 1,
      });
      setSelectedFile1(null);
      setSelectedFile2(null);
      setImagePreview1(null);
      setImagePreview2(null);

      alert('Product created successfully!');
    } catch (error) {
      console.error('Error creating product:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ margin: 'auto', mt: 4, backgroundColor: "white" }}>
      <Typography variant="h4" gutterBottom>
        Thêm sản phẩm mới
      </Typography>
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Product Name"
                name="name_product"
                value={product.name_product}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid style={{marginTop:"8px",paddingBottom:"8px" ,backgroundColor: "black", border: 'solid', borderWidth: 1, borderColor: "black", marginLeft: "8px" }} item xs={12}>
              <Typography color={'white'} >Chi tiết sản phẩm</Typography>
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                style={{ color: 'white' }}
                value={product.product_detail}
                onChange={(value) => handleQuillChange(value, 'product_detail')}
              />
            </Grid>
            <Grid style={{paddingBottom:"8px", backgroundColor: "black", border: 'solid', borderWidth: 1, borderColor: "black", marginTop: "8px", marginLeft: "8px" }} item xs={12}>
              <Typography color={'white'} >Chi tiết kỹ thuật</Typography>
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                style={{ color: 'white' }}
                value={product.technical_detail}
                onChange={(value) => handleQuillChange(value, 'technical_detail')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Made In"
                name="made_in"
                value={product.made_in}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Keywords"
                name="keywords"
                value={product.keywords}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Giá"
                name="price"
                value={product.price}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="id_category-label">Danh mục</InputLabel>
                <Select
                  labelId="id_category-label"
                  name="id_category"
                  value={product.id_category}
                  onChange={handleChange}
                  label="Category"
                >
                  <MenuItem value={1}>Tinh Dầu</MenuItem>
                  <MenuItem value={2}>Pod Máy</MenuItem>
                  <MenuItem value={3}>Pod 1 lần</MenuItem>
                  <MenuItem value={4}>Phụ kiện</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained" component="label" style={{ width: "50%", display: "flex", justifyContent: "center" }}>
                Upload Image
                <input type="file" hidden onChange={handleFileChange1} />
              </Button>
              {imagePreview1 && (
                <Box mt={2} textAlign="center">
                  <img src={imagePreview1} alt="Image Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained" component="label" style={{ width: "50%", display: "flex", justifyContent: "center" }}>
                Upload Image1
                <input type="file" hidden onChange={handleFileChange2} />
              </Button>
              {imagePreview2 && (
                <Box mt={2} textAlign="center">
                  <img src={imagePreview2} alt="Image1 Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <Button type="submit" variant="contained" color="primary">
                Create Product
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};


export default ParentProductForm;
